import React from "react";
import "./Model.scss";
/**
 * Renders a modal component with the given children.
 *
 * @param {Object} props - The properties for the modal component.
 * @param {ReactNode} props.children - The content to be displayed inside the modal.
 * @param {String} props.wrapperClassName - The content to be displayed inside the modal.
 * @return {JSX.Element} The rendered modal component.
 */
function Model({ children, wrapperClassName }) {
  return (
    <div className={`Model__wrapper ${wrapperClassName}`}>
      <div className="Model__content">{children}</div>
    </div>
  );
}

export default Model;
