function getDateTimeSuffix(date) {
  const ones = date % 10;
  const tens = Math.floor(date / 10) % 10;
  if (tens !== 1) {
    switch (ones) {
      case 1:
        return "st";
      case 2:
        return "nd";
      case 3:
        return "rd";
      default:
        return "th";
    }
  } else {
    return "th";
  }
}
export { getDateTimeSuffix };
