import React from "react";
import { Document, Page, Text, View } from "@react-pdf/renderer";
import styles from "./styles.js";
import currencyFormatter from "../../../util/currencyFormatter.js";

/**
 * Renders a PDF document containing merchandise information.
 *
 * @param {Object} props - The component props.
 * @param {Array} props.merchandises - The array of merchandise objects.
 * @param {string} props.merchandises[].name - The name of the merchandise.
 * @param {string} props.merchandises[].contact - The contact information.
 * @param {string} props.merchandises[].deliveryMethod - The delivery method.
 * @param {number} props.merchandises[].total - The total price.
 * @param {Array} props.merchandises[].selectedProducts - The selected products.
 * @param {string} props.merchandises[].size - The t-shirt size.
 * @param {Object} props.merchandises[].deliveryData - The delivery data.
 * @param {string} props.merchandises[].deliveryData.address - The delivery address.
 * @param {string} props.merchandises[].deliveryData.city - The delivery city.
 * @param {string} props.merchandises[].deliveryData.district - The delivery district.
 * @param {string} props.merchandises[].deliveryData.postalCode - The delivery postal code.
 * @return {JSX.Element} The rendered PDF document.
 */
function MerchandisePdf({merchandises}) {
  return (
    <Document
      author="Team TCMU"
      title="Team TCMU"
      subject="Team TCMU"
      creator="Team TCMU"
      
    >
      <Page size={"A4"} orientation="landscape" style={styles.page}>
        <View
          style={[
            styles.section_wrapper,
            {
              borderTop: "1px solid #B6B6B6",
            },
          ]}
        >
           <Text style={[styles.th,{
            width:"4%"
           }]}>#</Text>
          <Text style={styles.th}>Name</Text>
          <Text style={styles.th}>Contact</Text>
          <Text style={styles.th}>Delivery Method</Text>
          <Text style={styles.th}>Address</Text>
          <Text style={styles.th}>Price</Text>
          <Text style={styles.th}>T-shirt size</Text>
          <Text style={styles.th}>Items</Text>
        </View>
          {merchandises.map(({name,contact,deliveryMethod,total,selectedProducts,size,deliveryData}, index)=>(
        <View style={styles.section_wrapper} key={index}>
        <Text style={[styles.td,{
          width:"4%"        }]}>{index + 1}</Text>
        <Text style={styles.td}>{name}</Text>
        <Text style={styles.td}>{contact}</Text>
        <Text style={styles.td}>{deliveryMethod}</Text>
        <Text style={styles.td}>{deliveryData?.address? `${deliveryData?.address}, ${deliveryData?.city}, ( ${deliveryData?.district}:${deliveryData?.postalCode})`:"-"}</Text>
        <Text style={styles.td}>{currencyFormatter(deliveryMethod === "Courier"? total +500 : total)}</Text>
        <Text style={styles.td}>{size?size:"-"}</Text>
        <View style={styles.td}>
            {selectedProducts.map((product, index) => (
              <Text key={index}>{product.name}</Text>
            ))}
        </View>
          </View>
          ))}
      </Page>
    </Document>
  );
}

export default MerchandisePdf;
