import React, { useEffect, useState } from "react";
import "./CompetitionRegistration.scss";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import Input from "../../../../components/Input/Input";
import { Form, Formik } from "formik";
import { useNavigate } from "react-router-dom";
import Model from "../../../../components/Model";
import * as Yup from "yup";
import enumValues from "../../../../constants/enum";
import { getAllRecords } from "../../../../hooks/useFetch";
function CompetitionRegistration() {
  const [searchText, setSearchText] = useState("");
  // const [showModal, setShowModal] = useState(false);
  const [records, setRecords] = useState([]);
  const navigate = useNavigate();

  useEffect(() => {
    getAllRecords(enumValues.SCHOOL_REGISTRATIONS, "asc").then((data) => {
      setRecords(data);
    });
  }, []);

  useEffect(() => {
    if (searchText) {
      const filteredRecords = records.filter((record) => {
        return (
          record.schoolName.toLowerCase().includes(searchText.toLowerCase()) ||
          record.id.toLowerCase().includes(searchText.toLowerCase()) ||
          record.mediaUnitName
            .toLowerCase()
            .includes(searchText.toLowerCase()) ||
          record.mediaUnitEmail.toLowerCase().includes(searchText.toLowerCase())
        );
      });
      setRecords(filteredRecords);
    } else {
      getAllRecords(enumValues.SCHOOL_REGISTRATIONS, "asc").then((data) => {
        setRecords(data);
      });
    }
  }, [searchText]);

  return (
    <DashboardLayout header="Competition Registration">
      <div className="CompetitionRegistration__search">
        <h3>School List</h3>
        <div className="CompetitionRegistration__search__wrapper">
          {/* <i
            className="bx bxs-trash-alt CompetitionRegistration__delete__all"
            onClick={() => setShowModal(true)}
          ></i> */}
          <Formik initialValues={{ search: "" }}>
            <Form>
              <Input
                value={searchText}
                name="search"
                type="search"
                placeholder="Search for school"
                onChange={(value) => setSearchText(value)}
                wrapperClasses="CompetitionRegistration__search__input"
              />
            </Form>
          </Formik>
        </div>
      </div>
      <div className="table-responsive mt-5">
        <table>
          <thead>
            <tr>
              <th>School Name</th>
              <th>School ID</th>
              <th>MU Name</th>
              <th>Email</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {records.map((record) => (
              <tr key={record._id}>
                <td>{record.schoolName}</td>
                <td>{record.id}</td>
                <td>{record.mediaUnitName}</td>
                <td>{record.mediaUnitEmail}</td>
                <td>
                  <span>
                    <i
                      className="bx bx-right-arrow-circle"
                      onClick={() =>
                        navigate(`/day/registration/${record._id}`)
                      }
                    ></i>{" "}
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </DashboardLayout>
  );
}

export default CompetitionRegistration;
