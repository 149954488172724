import React, { useEffect, useRef, useState } from "react";
import "./AttendanceMark.scss";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import QrScanner from "qr-scanner";
import Badge from "../../../../components/Badge";
import enumValues from "../../../../constants/enum";
import { getSingleRecord } from "../../../../hooks/useFetch";
import { updateDocument } from "../../../../hooks/useSave";
function AttendanceMark() {
  const videoElementRef = useRef(null);
  const [scanned, setScannedText] = useState("");
  const [individualData, setIndividualData] = useState({});

  const handleScan = async (scannedText) => {
    try {
      const indlData = await getSingleRecord(
        enumValues.INDIVIDUAL_REGISTRATIONS,
        scannedText
      );
      if (!indlData?.school) {
        setScannedText("");
        setIndividualData({});
        return;
      }
      let tempData = { ...indlData };
      //  FETCH SCHOOL DATA
      const schoolData = await getSingleRecord(
        enumValues.SCHOOL_REGISTRATIONS,
        indlData?.school
      );
      tempData.school = schoolData;

      setIndividualData(tempData);
      // UPDATE DATA
      if (!tempData?.attended) {
        let updatableData = { ...indlData };
        updatableData.attended = true;
        await updateDocument(
          enumValues.INDIVIDUAL_REGISTRATIONS,
          scannedText,
          updatableData
        );

        tempData.attended = true;
        setIndividualData(tempData);
      }
    } catch (error) {
      console.log(error);
      setIndividualData({});
      setScannedText("");
    }
  };

  useEffect(() => {
    const video = videoElementRef.current;
    const qrScanner = new QrScanner(
      video,
      (result) => {
        setScannedText(result.data);
        handleScan(result.data);
      },
      {
        returnDetailedScanResult: true,
        highlightScanRegion: true,
        highlightCodeOutline: true,
      }
    );
    qrScanner.start();

    return () => {
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);
  return (
    <DashboardLayout header="Attendance Mark">
      <div className="AttendanceMark__wrapper container">
        <h2 className="text-light">Scan QR to mark attendance</h2>
        <div className="AttendanceMark__qr__section w-100 mt-4">
          <video className="AttendanceMark__qrVideo" ref={videoElementRef} />
          <div className="AttendanceMark__qr__section__data">
            <table>
              <tbody>
                <tr>
                  <td>Name</td>
                  <td>: {individualData?.teamForm?.[0]?.stdName}</td>
                </tr>
                <tr>
                  <td>School</td>
                  <td>: {individualData?.school?.schoolName}</td>
                </tr>
              </tbody>
            </table>
            {individualData?.attended ? (
              <Badge>
                &#10004; Attendance marked {individualData?.attended}
              </Badge>
            ) : null}

            {!scanned ? (
              <Badge type="danger">&#10005; No QR code found</Badge>
            ) : null}
          </div>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default AttendanceMark;
