import React, { useEffect, useState } from "react";
import "./Dashboard.scss";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import { Link } from "react-router-dom";
import enumValues from "../../../../constants/enum";
import { getAllRecords } from "../../../../hooks/useFetch";

function Dashboard() {
  const [schoolRegistraionCount, setSchoolRegistraionCount] = useState(0);
  const [individualRegistraionCount, setIndividualRegistraionCount] =
    useState(0);

  useEffect(() => {
    getAllRecords(enumValues.SCHOOL_REGISTRATIONS).then((data) => {
      setSchoolRegistraionCount(data.length);
    });

    getAllRecords(enumValues.INDIVIDUAL_REGISTRATIONS).then((data) => {
      setIndividualRegistraionCount(data.length);
    });

   
  }, []);
  return (
    <DashboardLayout>
      <div className="row m-0 p-0 Dashboard__cards">
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card">
            <h5>School Registrations:</h5>
            <h1>{schoolRegistraionCount.toString().padStart(3, "0")}</h1>
            <Link to="competition-registration">
              <small>
                See all registrations &nbsp;
                <i className="bx bx-right-arrow-alt"></i>
              </small>
            </Link>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card">
            <h5>Individual Registrations:</h5>
            <h1>{individualRegistraionCount.toString().padStart(3, "0")}</h1>
            <Link to="competition-registration">
              <small>
                See all registrations &nbsp;
                <i className="bx bx-right-arrow-alt"></i>
              </small>
            </Link>
          </div>
        </div>
        {/* <div className="col-lg-4 col-md-12 col-sm-12 mb-4">
          <div className="card">
            <h5>Total Competitors:</h5>
            <h1>{competitionCount.toString().padStart(3, "0")}</h1>
            <Link to="">
              <small>
                See all news posts &nbsp;
                <i className="bx bx-right-arrow-alt"></i>
              </small>
            </Link>
          </div>
        </div> */}
      </div>
      <div className="row m-0 p-0 Dashboard__chart"></div>
    </DashboardLayout>
  );
}

export default Dashboard;
