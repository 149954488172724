import React, { useEffect } from "react";
import { signOut } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";

function Logout() {
  const navigate = useNavigate();
  useEffect(() => {
    signOut().then(() => {
      navigate("/login");
    });
  }, [navigate]);
  return <div>Logout</div>;
}

export default Logout;
