import { Document, Page, Text, View } from "@react-pdf/renderer";
import React from "react";
import styles from "./styles.js";

function PDF({ data, ageCategory, school, event, medium }) {
  return (
    <Document
      author="Team TCMU"
      title="Team TCMU"
      subject="Team TCMU"
      creator="Team TCMU"
    >
      <Page size={"A4"} style={styles.page}>
        <View
          style={[
            styles.section_wrapper,
            {
              borderBottom: "0",
              borderTop: "0",
              borderLeft: "0",
              borderRight: "0",
            },
          ]}
        >
          <Text style={styles.th}>School: {school}</Text>
          <Text style={styles.th}>Event: {event}</Text>
          <Text style={styles.th}>Age category: {ageCategory}</Text>
          <Text style={styles.th}>Medium: {medium}</Text>
        </View>
        <View
          style={[
            styles.section_wrapper,
            {
              borderTop: "1px solid #B6B6B6",
            },
          ]}
        >
          <Text style={styles.th}>#</Text>
          <Text style={styles.th}>Name</Text>
          <Text style={styles.th}>School</Text>
          <Text style={styles.th}>Std ID</Text>
          <Text style={styles.th}>Event</Text>
          <Text style={styles.th}>Medium</Text>

          <Text style={styles.th}>Age category</Text>
        </View>
        {data.map((item, index) => (
          <View style={styles.section_wrapper} key={index}>
            <Text style={[styles.td, { width: "5%" }]}>{index + 1}</Text>
            <Text style={styles.td}>{item?.teamForm?.[0]?.stdName}</Text>
            <Text style={styles.td}>{item?.school?.schoolName}</Text>
            <Text style={styles.td}>{item?.id}</Text>
            <Text style={styles.td}>{item?.event?.event}</Text>
            <Text style={styles.td}>{item?.medium}</Text>
            <Text style={styles.td}>{item?.ageCategory}</Text>
          </View>
        ))}
      </Page>
    </Document>
  );
}

export default PDF;
