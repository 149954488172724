import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import { toast } from "react-toastify";
import { getAllRecords } from "../../../../hooks/useFetch";
import enumValues from "../../../../constants/enum";

function DayFeedBacks() {
  const [data, setData] = useState([]);

  useEffect(() => {
    fetchData();
  }, []);


  /**
   * Fetches all records from the PRABHASHWARA_FEEDBACK_COLLECTION collection and updates the state variables accordingly.
   * If there is an error in fetching the data, it shows an error toast.
   */
  const fetchData = async () => {
    try {
      // Fetch all records from the PRABHASHWARA_FEEDBACK_COLLECTION collection in ascending order
      const records = await getAllRecords(enumValues.PRABHASHWARA_FEEDBACK_COLLECTION);
      setData(records);

    } catch (error) {
      // Show an error toast if there is an error in fetching the data
      toast.error("Failed to fetch data");
    }
  };
  

  return (
    <DashboardLayout header="Prabhashwara FeedBacks">
       <div className="row m-0 p-0 Dashboard__cards">
        <div className="col-lg-4 col-md-6 col-sm-12">
          {data.filter((_,index)=> index % 3 === 0).map((feedback,key) => (
          
              <div className="card my-4" key={key}>
                <h5 className="text-light">{feedback?.nameOfSchool}</h5>
                <h6 className="text-light">{feedback?.name}</h6>
                <small className="text-light">{feedback?.feedback}</small>
              </div>
            
            
          ))}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          {data.filter((_,index)=> index % 3 === 1).map((feedback,key) => (
          
              <div className="card my-4" key={key}>
                <h5 className="text-light">{feedback?.nameOfSchool}</h5>
                <h6 className="text-light">{feedback?.name}</h6>
                <small className="text-light">{feedback?.feedback}</small>
              </div>
            
            
          ))}
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12">
          {data.filter((_,index)=> index % 3 === 2).map((feedback,key) => (
          
              <div className="card my-4" key={key}>
                <h5 className="text-light">{feedback?.nameOfSchool}</h5>
                <h6 className="text-light">{feedback?.name}</h6>
                <small className="text-light">{feedback?.feedback}</small>
              </div>
            
            
          ))}
        </div>
        
        </div>
    </DashboardLayout>
  );
}

export default DayFeedBacks;
