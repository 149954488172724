import { collection, addDoc, doc, updateDoc } from "firebase/firestore";
import { db } from "../config/firebase";

/**
 * Saves a document to a Firestore collection.
 *
 * @param {string} collectionName - The name of the collection to save the document to.
 * @param {Object} values - The data to be saved as a document.
 * @return {Promise<string>} - A promise that resolves to "data saved" upon successful saving.
 * @throws {Error} - If there's an error saving the document.
 */
const saveDocument = async (collectionName, values) => {
  try {
    // Get a reference to the collection in Firestore.
    const saveReadyCollection = collection(db, collectionName);
    // Add a document to the collection with the provided data,
    // including a timestamp field set to the current date.
    const document = await addDoc(saveReadyCollection, {
      ...values,
      timestamp: Date.now(),
    });
    // Return the ID of the saved document.
    return document;
  } catch (error) {
    // If there's an error, rethrow it.
    throw error;
  }
};

/**
 * Updates a document in a Firestore collection.
 *
 * @param {string} collectionName - The name of the collection to update the document in.
 * @param {string} id - The ID of the document to update.
 * @param {Object} values - The data to update the document with.
 * @return {Promise<DocumentReference>} - A promise that resolves to the updated document reference.
 * @throws {Error} - If there's an error updating the document.
 */
const updateDocument = async (collectionName, id, values) => {
  try {
    // Get a reference to the document in Firestore.
    const document = doc(db, collectionName, id);

    // Update the document with the provided data.
    await updateDoc(document, values);

    // Return the ID of the updated document.
    return document;
  } catch (error) {
    // If there's an error, rethrow it.
    throw error;
  }
};

export { saveDocument, updateDocument };
