import React, { useState } from "react";
import "./input.scss";
import { useField } from "formik";
/**
 * Renders an input component with optional password toggle and error handling.
 *
 * @param {string} type - The type of input field. Defaults to "text".
 * @param {string} label - The label text for the input field.
 * @param {string} placeholder - The placeholder text for the input field.
 * @param {string} value - The initial value of the input field.
 * @param {string} id - The id of the input field.
 * @param {function} onChange - The callback function for when the input value changes.
 * @param {string} name - The name of the input field.
 * @param {string} wrapperClasses - Additional CSS classes for the input wrapper.
 * @return {JSX.Element} The rendered input component.
 */
function Input({
  type = "text",
  label,
  placeholder,
  value,
  id,
  onChange,
  name,
  wrapperClasses,
  disabled,
  min,
  max,
}) {
  const [field, meta] = useField(name);
  const [showPassword, setShowPassword] = useState(false);
  const onPasswordToggle = () => {
    if (type === "password") {
      setShowPassword(!showPassword);
    }
  };

  const handleOnChange = (e) => {
    if (onChange) {
      onChange(e.target.value);
    } else {
      field.onChange(e);
    }
  };
  return (
    <>
      <div className={`input__wrapper ${wrapperClasses}`}>
        {type === "password" ? (
          <i
            className={`bx ${!showPassword ? "bx-show" : "bx-hide"} inputIcon`}
            onClick={onPasswordToggle}
          ></i>
        ) : null}
        {type === "search" ? <i className="bx bx-search inputIcon"></i> : null}
        {type !== "search" ? (
          <label
            htmlFor={id}
            className={meta.touched[name] || field.value ? "active" : ""}
          >
            {label}
          </label>
        ) : null}
        <input
          {...field}
          type={showPassword ? "text" : type}
          value={value ? value : field.value}
          name={name}
          id={id}
          onChange={handleOnChange}
          placeholder={placeholder}
          disabled={disabled || field.disabled}
          min={min}
          max={max}
        />
      </div>
      {meta.error && meta.touched ? (
        <small className="text-danger input__error d-block align-self-start">
          {meta.error}
        </small>
      ) : null}
    </>
  );
}

export default Input;
