import React from "react";
import "./ClipboardCopy.scss";
import { toast } from "react-toastify";
function ClipBoardCopy({ textToCopy, wrapperClass = "" }) {
  const handleCopy = async (e, copyText) => {
    try {
      // change icon to tick
      e.target.classList.replace("bx-copy", "bx-check");
      // copy text
      await navigator.clipboard.writeText(copyText);
      setTimeout(() => {
        e.target.classList.replace("bx-check", "bx-copy");
      }, 2000);
    } catch (error) {
      toast.error("cant copy text to clipboard");
    }
  };
  return (
    <div className={`ClipBoardCopy__copyText__area ${wrapperClass}`}>
      <span>{textToCopy}</span>
      <i className="bx bx-copy" onClick={(e) => handleCopy(e, textToCopy)}></i>
    </div>
  );
}

export default ClipBoardCopy;
