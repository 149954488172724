import React from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../config/firebase";
import { Navigate } from "react-router-dom";

function Auth({ children }) {
  const [user, loading] = useAuthState(auth);
  if (loading) {
    return <div>Loading...</div>;
  }
  if (!user || user === null) {
    return <Navigate to="/login" />;
  }

  return children;
}

export default Auth;
