import React, { useEffect, useState } from "react";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import Model from "../../../../components/Model";
import MerchandisePdf from "../../../../components/PDF/Merchandise";
import { toast } from "react-toastify";
import { getAllRecords } from "../../../../hooks/useFetch";
import enumValues from "../../../../constants/enum";
import currencyFormatter from "../../../../util/currencyFormatter";
import Badge from "../../../../components/Badge";
import { updateDocument } from "../../../../hooks/useSave";
import { PDFDownloadLink } from "@react-pdf/renderer";
function Merchandise() {
  const [merchandises, setMerchandises] = useState([]);
  const [totalPrice, setTotalPrice] = useState(0);
  const [modelOpen, setModelOpen] = useState(false);
  const [modelData, setModelData] = useState({});
  const [courierOrders, setCourierOrders] = useState(0);

  useEffect(() => {
    //  FETCH SCHOOLS
    fetchMerchandises();
  }, []);
  /**
   * This function fetches all the merchandises from the PRABHASHWARA_MERCHANDISE_COLLECTION
   * collection in ascending order. It then calculates the total price of all the merchandises
   * and updates the state variable merchandises and totalPrice accordingly. If there is an
   * error in fetching the merchandises, it shows an error toast.
   */
  const fetchMerchandises = async () => {
    try {
      // Fetch all the merchandises from the PRABHASHWARA_MERCHANDISE_COLLECTION collection
      const data = await getAllRecords(
        enumValues.PRABHASHWARA_MERCHANDISE_COLLECTION,
        "asc"
      );

      //  Set the fetched merchandises currier orders to the state variable merchandises
      setCourierOrders(data.filter((merchandise) => merchandise.deliveryMethod === "Courier").length)


      // Set the fetched merchandises to the state variable merchandises
      setMerchandises(data);
       // Calculate the total price of all the merchandises
      const totalPrices = data.reduce((total, item) => {
        // Check if the delivery method is courier and add 500 to the total price
         return item.deliveryMethod=== "Courier" ? (total += item?.total + 500 || 0) : (total += item?.total || 0);
      }, 0);
      
      // Set the calculated total price to the state variable totalPrice
      setTotalPrice(totalPrices);
    } catch (error) {
      // Show an error toast if there is an error in fetching the merchandises
      toast.error("Failed to fetch merchandises");
    }
  };

  /**
   * This function is used to open the detailed view of a specific merchandise.
   * It sets the selected merchandise to the state variable modelData and
   * sets the state variable modelOpen to true.
   *
   * @param {string} id - The id of the merchandise to be displayed.
   */
  const detailedView = (id) => {
    // Find the merchandise with the given id from the merchandises array
    const selectedMerchandise = merchandises.find(
      (merchandise) => merchandise._id === id
    );

    // Set the selected merchandise to the state variable modelData
    setModelData(selectedMerchandise);

    // Set the state variable modelOpen to true to open the detailed view
    setModelOpen(true);
  };

const statusUpdate = async (id) => {
  try {
    const selectedOrder = merchandises.find((merchandise) => merchandise._id === id);
    const updatedOrder = { ...selectedOrder, status: !selectedOrder.status };
    await updateDocument(enumValues.PRABHASHWARA_MERCHANDISE_COLLECTION,id, updatedOrder);
    fetchMerchandises();
    toast.success("Status updated successfully");
  } catch (error) {
    console.log(error);
    toast.error("Failed to update status");
  }
}


  return (
    <DashboardLayout header="Prabhashwara Merchandise">
      <div className="row m-0 p-0 Dashboard__cards">
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card">
            <h5>Total Orders:</h5>
            <h1>{merchandises.length.toString().padStart(3, "0")}</h1>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card">
            <h5>Total courier orders:</h5>
            <h1>{courierOrders.toString().padStart(3, "0")}</h1>
          </div>
        </div>
        <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
          <div className="card">
            <h5>Total school pickup orders:</h5>
            <h1>{String(merchandises.length - courierOrders).padStart(3, "0")}</h1>
          </div>
        </div>
      </div>
      <div className="row m-0 p-0 Dashboard__cards">
      
        <div className="col-lg-6 col-md-12 col-sm-12 mb-4">
          <div className="card">
            <h5>Total Amount:</h5>
            <h1>{currencyFormatter(totalPrice)}</h1>
          </div>
        </div>
    
      </div>
     
      <div className="table-responsive mt-4">
        <table>
          <thead>
            <tr>
                <th></th>
              <th>Name</th>
              <th>Contact</th>
              <th>Delivery Method</th>
              <th>Price</th>
              <th>
              <PDFDownloadLink  className="text-decoration-none" document={<MerchandisePdf merchandises={merchandises} />}>
              <button className="w-100 eventsPrint" type="button">
                       <i className="bx bx-printer"></i>
                    </button>
              </PDFDownloadLink>
              </th>
            </tr>
          </thead>

          <tbody>
            {merchandises?.map(
              ({ _id, name, contact, deliveryMethod, total,status=false }) => (
                <tr key={_id}>
                  <td>
                    <Badge 
                    onClick={()=>statusUpdate(_id)}
                    type={status ? "success" : "warning"}>
                      {status ? "Dispatched" : "Processing"}
                    </Badge>
                    </td>  
                  <td>{name}</td>
                  <td>{contact}</td>
                  <td>{deliveryMethod}</td>
                  <td>{currencyFormatter(total)}</td>
                  <td>
                    <span onClick={() => detailedView(_id)}>
                      <i className="bx bxs-archive-out"></i>
                    </span>
                  </td>
                </tr>
              )
            )}
          </tbody>
        </table>
      </div>
      {modelOpen ? (
        <Model wrapperClassName="CompetitionStudents__model">
          <header className="w-100 d-flex align-items-center justify-content-between">
            <h4 className="text-light">Full Details</h4>
            <button onClick={() => setModelOpen(false)}>
              <i className="bx bx-x"></i> <span>Close</span>
            </button>
          </header>
          <section className="d-flex align-items-center justify-content-center mt-4 CompetitionStudents__model__Qr__section">
            <div className="table__content w-100">
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>{modelData?.name}</td>
                  </tr>
                  <tr>
                    <td>Contact</td>
                    <td>{modelData?.contact}</td>
                  </tr>
                  <tr>
                    <td>Delivery Method</td>
                    <td>{modelData?.deliveryMethod}</td>
                  </tr>
                  <tr>
                    <td>T-shirt Size</td>
                    <td>{modelData?.size}</td>
                  </tr>
                  <tr>
                    <td>Price</td>
                    <td>{currencyFormatter(modelData?.total)}</td>
                  </tr>
                  <tr>
                    <td>Address</td>
                    <td>
                      {modelData?.deliveryData?.address},{modelData?.deliveryData?.city} 
                      (  {modelData?.deliveryData?.district} : {modelData?.deliveryData?.postalCode})
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </section>
          <section className="d-flex align-items-center justify-content-between mt-4">
            <div className="table-responsive mt-4 w-100">
              <table>
                <thead>
                  <tr>
                    <th>Product</th>
                    <th>Qty</th>
                    <th>Price</th>
                  </tr>
                </thead>
                <tbody>
                  {modelData?.selectedProducts?.map(
                    ({ name, price, quantity = 1 }, key) => (
                      <tr key={key}>
                        <td>{name}</td>
                        <td>{quantity}</td>
                        <td>{currencyFormatter(price)}</td>
                      </tr>
                    )
                  )}
                </tbody>
              </table>
            </div>
          </section>
          <section className="CompetitionStudents__model__clipboard__section mt-4">
            <span className="CompetitionStudents__model__clipboard__section__title">
              Payment slip
            </span>
            <div className="ClipBoardCopy__copyText__area CompetitionStudents__model__clipboard">
              <span>{modelData?.slip}</span>
              <i
                className="bx bx-up-arrow-alt"
                style={{
                  transform: "rotate(35deg)",
                }}
                onClick={() => window.open(modelData?.slip, "_blank")}
              ></i>
            </div>
          </section>
        </Model>
      ) : null}
    </DashboardLayout>
  );
}

export default Merchandise;
