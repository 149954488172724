/**
 * Formats the given amount into a currency string.
 *
 * @param {number|string} amount - The amount to be formatted.
 * @return {string} The formatted currency string.
 */
export default function currencyFormatter(amount) {
    // Create a number formatter with the specified options.
    // The options include the style as "currency" and the currency as "LKR".
    const formatter = new Intl.NumberFormat("en-US", {
        style: "currency",
        currency: "LKR",
    });

    // Format the given amount using the formatter and return the result.
    return formatter.format(amount);
}