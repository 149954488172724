import { StyleSheet } from "@react-pdf/renderer";

const styles = StyleSheet.create({
  page: {
    backgroundColor: "#ffff",
    height: "100%",
    position: "relative",
    padding: "5px",
  },
  section_wrapper: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "center",
    borderBottom: "1px solid #B6B6B6",
    borderLeft: "1px solid #B6B6B6",
    borderRight: "1px solid #B6B6B6",
    padding: "5px",
  },
  th: {
    fontSize: 10,
    fontWeight: "bold",
    width: "13.7%",
    textAlign: "left",
  },
  td: {
    fontSize: 8,
    width: "13.7%",
  },

});

export default styles;
