import React from "react";
import "./News.scss";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";

function News() {
  return (
    <DashboardLayout header="News">
      <div className="coming__soon__wrapper">
        <div className="coming__soon__content">
          <i className="bx bx-info-circle"></i>
          <h4>Coming soon</h4>
          <p>
          This section give ability to post, edit news post.
          </p>
        </div>
      </div>
    </DashboardLayout>
  );
}

export default News;
