import React, { useEffect } from "react";
import "./Login.scss";

import Input from "../../../components/Input";
import { Form, Formik } from "formik";
import * as Yup from "yup";
import { TitleImage } from "../../../constants/asset";
import { toast } from "react-toastify";
import { login } from "../../../hooks/useAuth";
import { useNavigate } from "react-router-dom";
import { useAuthState } from "react-firebase-hooks/auth";
import { auth } from "../../../config/firebase";
// import { Link } from "react-router-dom";
function Login() {
  const navigate = useNavigate();
  const [user] = useAuthState(auth);

  useEffect(() => {
    if (user) {
      navigate("/");
    }
  }, [user, navigate]);

  const initialValues = {
    username: "",
    password: "",
  };

  const validationSchema = Yup.object({
    username: Yup.string()
      .required("User name is required")
      .email("Invalid email address"),
    password: Yup.string().required("Password is required"),
  });

  const handleOnSubmit = async (values, formikActions) => {
    try {
      const authUser = await login(values.username, values.password);
      if (authUser) {
        navigate("/dashboard");
      }
    } catch (error) {
      toast.error("Invalid user name or password");
    }
  };
  return (
    <div className="login__wrapper container-fluid p-0 m-0 w-100 h-100">
      <div className="container">
        <div className="card login__card">
          <img src={TitleImage} alt="TitleImage" className="TitleImage" />
          <div className="login__card__form">
            <h2 className="my-3">Admin Login</h2>
            <Formik
              initialValues={initialValues}
              onSubmit={handleOnSubmit}
              validationSchema={validationSchema}
            >
              <Form className="login__card__form__wrapper">
                <div className="inputWrapper w-100">
                  <Input
                    id="login__username"
                    label="User Name"
                    name="username"
                  />
                </div>
                <div className="inputWrapper w-100">
                  <Input
                    id="login__password"
                    type="password"
                    label="Password"
                    name="password"
                  />
                </div>
                <button className="login__button">Login</button>
              </Form>
            </Formik>
            <a href="/" className="mt-5">
              Forgot password?
            </a>
          </div>
        </div>
      </div>
      <p className="login__footer__text">
        This is an admin login for the tcmuofficial website, and please make
        sure to avoid misuse.
      </p>
    </div>
  );
}

export default Login;
