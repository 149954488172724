import React from "react";
import "./Badge.scss";

function Badge({ text, type = "success", text__color, onClick, children }) {
  return (
    <div className={`Badge__wrapper user-select-none ${type}`} onClick={onClick}>
      {children ? children : text}
    </div>
  );
}

export default Badge;
