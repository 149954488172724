import React, { useEffect, useState } from "react";
import "./EventViseList.scss";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import Select from "../../../../components/Select";
import Model from "../../../../components/Model";
import ClipBoardCopy from "../../../../components/ClipBoardCopy";
import Badge from "../../../../components/Badge";
import QRCode from "qrcode.react";
import enumValues from "../../../../constants/enum";
import { getAllRecords, getSingleRecord } from "../../../../hooks/useFetch";
import { Form, Formik } from "formik";
import { PDFDownloadLink } from "@react-pdf/renderer";
import PDF from "../../../../components/PDF";
function EventViseList() {
  const [schools, setSchools] = useState([]);
  const [events, setEvents] = useState([]);
  const [allRegistrations, setAllRegistrations] = useState([]);
  const [eventRelatedAgeCategory, setEventRelatedAgeCategory] = useState([]);
  const [individualRegistrations, setIndividualRegistrations] = useState([]);
  const [mediumShow, setMediumShow] = useState(false);
  const [selectedEvent, setSelectedEvent] = useState("");
  const [selectedSchool, setSelectedSchool] = useState("");
  const [selectedAgeCategory, setSelectedAgeCategory] = useState("");
  const [selectedMedium, setSelectedMedium] = useState("");
  const [modelOpen, setModelOpen] = useState(false);
  const [selectedIndividualRegistration, setSelectedIndividualRegistration] =
    useState({});

  useEffect(() => {
    //  FETCH SCHOOLS
    getAllRecords(enumValues.SCHOOL_REGISTRATIONS, "asc")
      .then((schoolsData) => {
        setSchools(
          schoolsData.map((data) => {
            return { value: data._id, name: data.schoolName };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });
    //  FETCH EVENTS
    getAllRecords(enumValues.EVENT_COLLECTION)
      .then((eventsData) => {
        setEvents(
          eventsData.map((data) => {
            return { value: data._id, name: data.event };
          })
        );
      })
      .catch((error) => {
        console.log(error);
      });

    getAllRecords(enumValues.INDIVIDUAL_REGISTRATIONS).then((registrations) => {
      return registrations;
    });

    fetChData();
  }, []);
  const fetChData = async () => {
    let temp = [];
    const data = await getAllRecords(
      enumValues.INDIVIDUAL_REGISTRATIONS,
      "asc"
    );
    temp = [...data];
    const modifiedEventDataPromiseArray = temp.map((data) => {
      return getSingleRecord(enumValues.EVENT_COLLECTION, data.event).then(
        (eventData) => {
          return {
            ...data,
            event: {
              _id: eventData._id,
              event: eventData.event,
            },
          };
        }
      );
    });

    const modifiedEventData = await Promise.all(modifiedEventDataPromiseArray);

    temp = [...modifiedEventData];
    const modifiedSchoolDataPromiseArray = temp.map((data) => {
      return getSingleRecord(enumValues.SCHOOL_REGISTRATIONS, data.school).then(
        (schoolData) => {
          return {
            ...data,
            school: {
              _id: schoolData._id,
              schoolName: schoolData.schoolName,
            },
          };
        }
      );
    });

    const modifiedSchoolData = await Promise.all(
      modifiedSchoolDataPromiseArray
    );
    temp = [...modifiedSchoolData];
    setAllRegistrations(temp);
    setIndividualRegistrations(temp);
  };
  const handleEventChange = async (value) => {
    setSelectedEvent(value);
    if (value) {
      setMediumShow(false);
      setSelectedAgeCategory("");
      const data = await getSingleRecord(enumValues.EVENT_COLLECTION, value);
      const ageCategory = data?.age_category;
      setMediumShow(data.medium_show);
      setEventRelatedAgeCategory(
        ageCategory.map((data) => {
          return { name: data, value: data };
        })
      );
    } else {
      setEventRelatedAgeCategory([]);
      setSelectedAgeCategory("");
      setMediumShow(false);
    }
  };

  const handleFilter = async () => {
    let temp = [...allRegistrations];
    if (
      !selectedSchool &&
      !selectedEvent &&
      !selectedAgeCategory &&
      !selectedMedium
    ) {
      setIndividualRegistrations(allRegistrations);
      return;
    }
    if (
      !selectedSchool &&
      selectedEvent &&
      !selectedAgeCategory &&
      !selectedMedium
    ) {
      const filterData = temp.filter((data) => {
        return data.event._id === selectedEvent;
      });
      setIndividualRegistrations(filterData);
      return;
    }
    if (
      !selectedSchool &&
      selectedEvent &&
      selectedAgeCategory &&
      !selectedMedium
    ) {
      const filterData = temp.filter(
        (data) =>
          data.event._id === selectedEvent &&
          data.ageCategory === selectedAgeCategory
      );
      setIndividualRegistrations(filterData);
      return;
    }
    if (
      !selectedSchool &&
      selectedEvent &&
      selectedAgeCategory &&
      selectedMedium
    ) {
      const filterData = temp.filter(
        (data) =>
          data.event._id === selectedEvent &&
          data.ageCategory === selectedAgeCategory &&
          data.medium === selectedMedium
      );
      setIndividualRegistrations(filterData);
      return;
    }
    if (
      selectedSchool &&
      !selectedEvent &&
      !selectedAgeCategory &&
      !selectedMedium
    ) {
      const filterData = temp.filter((data) => {
        return data.school._id === selectedSchool;
      });
      setIndividualRegistrations(filterData);
      return;
    }
    if (
      selectedSchool &&
      selectedEvent &&
      !selectedAgeCategory &&
      !selectedMedium
    ) {
      const filterData = temp.filter(
        (data) =>
          data.school._id === selectedSchool && data.event._id === selectedEvent
      );
      setIndividualRegistrations(filterData);
      return;
    }

    if (
      selectedSchool &&
      selectedEvent &&
      !selectedAgeCategory &&
      !selectedMedium
    ) {
      const filterData = temp.filter(
        (data) =>
          data.school._id === selectedSchool && data.event._id === selectedEvent
      );
      setIndividualRegistrations(filterData);
      return;
    }
    if (
      selectedSchool &&
      selectedEvent &&
      selectedAgeCategory &&
      !selectedMedium
    ) {
      const filterData = temp.filter(
        (data) =>
          data.school._id === selectedSchool &&
          data.event._id === selectedEvent &&
          data.ageCategory === selectedAgeCategory
      );
      setIndividualRegistrations(filterData);
      return;
    }
    if (
      selectedSchool &&
      selectedEvent &&
      !selectedAgeCategory &&
      selectedMedium
    ) {
      const filterData = temp.filter(
        (data) =>
          data.school._id === selectedSchool &&
          data.event._id === selectedEvent &&
          data.medium === selectedMedium
      );
      setIndividualRegistrations(filterData);
      return;
    }

    if (
      selectedSchool &&
      selectedEvent &&
      selectedAgeCategory &&
      selectedMedium
    ) {
      const filterData = temp.filter(
        (data) =>
          data.school._id === selectedSchool &&
          data.event._id === selectedEvent &&
          data.ageCategory === selectedAgeCategory &&
          data.medium === selectedMedium
      );
      setIndividualRegistrations(filterData);
      return;
    }
  };
    const QRDownload = () => {
      const qrCodeURL = document
        .getElementById("STDQrCode")
        .toDataURL("image/png")
        .replace("image/png", "image/octet-stream");
      let link = document.createElement("a");
      link.download = `${selectedIndividualRegistration?.teamForm?.[0]?.stdName}-${selectedIndividualRegistration?.id}.png`;
      link.href = qrCodeURL;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    };

  const dataOpen = (id) => {
    setModelOpen(true);
    const selectedRegistration = allRegistrations.find(
      (registration) => registration._id === id
    );
    setSelectedIndividualRegistration(selectedRegistration);
  };

  const Language = {
    SM: "Sinhala",
    EM: "English",
  };

  return (
    <DashboardLayout header="Event Vise List">
      <Formik>
        <Form>
          <div className="row m-0 p-0">
            <div className="col-lg-3 col-md-12 col-sm-12 p-2 ">
              <Select
                wrapperClasses="EventViseList__select"
                name="school"
                onChange={(e) => {
                  setSelectedSchool(e.target.value);
                }}
                options={[
                  {
                    name: "Select School",
                    value: "",
                  },
                  ...schools,
                ]}
              />
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 p-2">
              <Select
                name="event"
                wrapperClasses="EventViseList__select"
                onChange={(e) => {
                  handleEventChange(e.target.value);
                }}
                options={[
                  {
                    name: "Select Event",
                    value: "",
                  },
                  ...events,
                ]}
              />
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 p-2">
              <Select
                wrapperClasses="EventViseList__select"
                name="ageCategory"
                disable={eventRelatedAgeCategory.length === 0}
                onChange={(e) => setSelectedAgeCategory(e.target.value)}
                options={[
                  {
                    name: "Select Age Category",
                    value: "",
                  },
                  ...eventRelatedAgeCategory,
                ]}
              />
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 p-2">
              <Select
                wrapperClasses="EventViseList__select"
                name="medium"
                disable={!mediumShow}
                onChange={(e) => setSelectedMedium(e.target.value)}
                options={[
                  {
                    name: "Select Medium",
                    value: "",
                  },
                  {
                    name: "English",
                    value: "EM",
                  },
                  {
                    name: "Sinhala",
                    value: "SM",
                  },
                ]}
              />
            </div>
            <div className="col-lg-2 col-md-12 col-sm-12 p-0 m-0">
              <div className="row p-0 m-0">
                <div className="col-lg-6 col-md-12 col-sm-12 p-2">
                  <button
                    className="eventsPrint"
                    type="button"
                    onClick={handleFilter}
                  >
                    Filter <i className="bx bx-filter-alt"></i>
                  </button>
                </div>
                <div className="col-lg-6 col-md-12 col-sm-12 p-2">
                  <PDFDownloadLink
                    className="text-decoration-none"
                    document={
                      <PDF
                        data={individualRegistrations}
                        ageCategory={selectedAgeCategory || "Any"}
                        school={
                          selectedSchool
                            ? individualRegistrations[0]?.school?.schoolName
                            : "Any"
                        }
                        event={
                          selectedEvent
                            ? individualRegistrations[0]?.event?.event
                            : "Any"
                        }
                        medium={selectedMedium || "Any"}
                      />
                    }
                  >
                    <button className="w-100 eventsPrint" type="button">
                      Print <i className="bx bx-printer"></i>
                    </button>
                  </PDFDownloadLink>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </Formik>
      <div className="table-responsive mt-4">
        <table>
          <thead>
            <tr>
              <th>Name</th>
              <th>School</th>
              <th>Contact number</th>
              <th>Event</th>
              <th>Medium</th>
              <th>Age category</th>
              <th></th>
            </tr>
          </thead>

          <tbody>
            {individualRegistrations.map((data) => {
              return (
                <tr key={data?._id}>
                  <td>{data?.teamForm?.[0]?.stdName}</td>
                  <td>{data?.school?.schoolName}</td>
                  <td>{data?.teamForm?.[0]?.stdContactNumber}</td>

                  <td>{data?.event?.event}</td>
                  <td>{data?.medium ? data?.medium : "NO MEDIUM"}</td>
                  <td>{data?.ageCategory}</td>
                  <td>
                    <span onClick={() => dataOpen(data?._id)}>
                      <i className="bx bxs-archive-out"></i>
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {modelOpen ? (
        <Model wrapperClassName="CompetitionStudents__model">
          <header className="w-100 d-flex align-items-center justify-content-between">
            <h4 className="text-light">Full Details</h4>
            <button onClick={() => setModelOpen(false)}>
              <i className="bx bx-x"></i> <span>Close</span>
            </button>
          </header>
          <section className="d-flex align-items-center justify-content-between mt-4 CompetitionStudents__model__Qr__section">
            <div className="table__content">
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>
                      : {selectedIndividualRegistration?.teamForm?.[0]?.stdName}
                    </td>
                  </tr>
                  <tr>
                    <td>Birthday</td>
                    <td>
                      : {selectedIndividualRegistration?.teamForm?.[0]?.stdDOB}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>
                      :{" "}
                      {
                        selectedIndividualRegistration?.teamForm?.[0]
                          ?.stdContactNumber
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>NIC</td>
                    <td>
                      : {selectedIndividualRegistration?.teamForm?.[0]?.nic}
                    </td>
                  </tr>
                  {selectedIndividualRegistration?.teamForm?.length > 1 ? (
                    <tr>
                      <td>Team Members</td>
                      <td>
                        <ol>
                          {selectedIndividualRegistration?.teamForm?.map(
                            (member, index) => (
                              <li key={index}>{member?.stdName} </li>
                            )
                          )}
                        </ol>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <div className="label__section d-flex align-items-center gap-2 mt-4 flex-wrap">
                <Badge
                  text={selectedIndividualRegistration?.id}
                  type="success"
                />
                <Badge
                  text={selectedIndividualRegistration?.event?.event}
                  type="primary"
                />
                <Badge
                  text={selectedIndividualRegistration?.ageCategory}
                  type="primary"
                />
                {selectedIndividualRegistration?.medium ? (
                  <Badge
                    text={Language[selectedIndividualRegistration?.medium]}
                    type="primary"
                  />
                ) : null}
              </div>
            </div>
            <div className="qr__code">
              <QRCode
                value={selectedIndividualRegistration?._id}
                size={200}
                style={{ height: "auto", maxWidth: "200px", width: "100%" }}
                onClick={QRDownload}
                id="STDQrCode"
              />
            </div>
          </section>
          {selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_SHORT_FILM ||
          selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_GRAPHIC_DESIGNING ||
          selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_PHOTOGRAPHY ? (
            <section className="CompetitionStudents__model__clipboard__section mt-4">
              <span className="CompetitionStudents__model__clipboard__section__title">
                The link issued for upload your design or video
              </span>
              <ClipBoardCopy
                textToCopy={`${window.location.protocol}//tcmuofficial.com${enumValues.PROJECT_PUBLISHING_URL}?individualId=${selectedIndividualRegistration?._id}`}
                wrapperClass="CompetitionStudents__model__clipboard"
              />
            </section>
          ) : null}
          {!selectedIndividualRegistration?.projectData &&
          (selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_SHORT_FILM ||
            selectedIndividualRegistration?.event?.event ===
              enumValues.EVENT_GRAPHIC_DESIGNING ||
            selectedIndividualRegistration?.event?.event ===
              enumValues.EVENT_PHOTOGRAPHY) ? (
            <section className="CompetitionStudents__model__clipboard__section mt-4">
              <span className="CompetitionStudents__model__clipboard__section__title">
                Design or video not submitted
              </span>
            </section>
          ) : null}
          {selectedIndividualRegistration?.projectData ? (
            <>
              <section className="d-flex align-items-center justify-content-between mt-4 CompetitionStudents__model__Qr__section">
                <div className="table__content">
                  <table className="">
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>
                          :{" "}
                          {selectedIndividualRegistration?.projectData?.project}
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>
                          :{" "}
                          {
                            selectedIndividualRegistration?.projectData
                              ?.description
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Used softwares</td>
                        <td>
                          :{" "}
                          {
                            selectedIndividualRegistration?.projectData
                              ?.softwares
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section className="CompetitionStudents__model__clipboard__section mt-4">
                <span className="CompetitionStudents__model__clipboard__section__title">
                  Design or video link
                </span>
                <div className="ClipBoardCopy__copyText__area CompetitionStudents__model__clipboard">
                  <span>
                    {selectedIndividualRegistration?.projectData?.projectLink}
                  </span>
                  <i
                    className="bx bx-up-arrow-alt"
                    style={{
                      transform: "rotate(35deg)",
                    }}
                    onClick={() =>
                      window.open(
                        selectedIndividualRegistration?.projectData
                          ?.projectLink,
                        "_blank"
                      )
                    }
                  ></i>
                </div>
              </section>
            </>
          ) : null}
        </Model>
      ) : null}
    </DashboardLayout>
  );
}

export default EventViseList;
