import React, { useEffect, useRef, useState } from "react";
import "./CompetitionStudents.scss";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import Input from "../../../../components/Input/Input";
import { Form, Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import Badge from "../../../../components/Badge";
import Select from "../../../../components/Select";
import {
  getSingleRecord,
  findByDocumentsKey,
} from "../../../../hooks/useFetch";
import enumValues from "../../../../constants/enum";
import Model from "../../../../components/Model";
import QRCode from "qrcode.react";
import ClipBoardCopy from "../../../../components/ClipBoardCopy/ClipBoardCopy";
import { toast } from "react-toastify";
import { updateDocument } from "../../../../hooks/useSave";

function CompetitionStudents() {
  const [searchText, setSearchText] = useState("");
  const [schoolData, setSchoolData] = useState({});
  const [individualRegistration, setIndividualRegistration] = useState([]);
  const [selectedIndividualRegistration, setSelectedIndividualRegistration] =
    useState({});
  const [selectedCategory, setSelectedCategory] = useState("Other Category");
  const [modelOpen, setModelOpen] = useState(false);
  const { schId } = useParams();

  useEffect(() => {
    fetchData();
  }, [selectedCategory]);

  useEffect(() => {
    if (searchText) {
      const filteredData = individualRegistration.filter((individualData) => {
        return individualData.teamForm[0].stdName
          .toLowerCase()
          .includes(searchText.toLowerCase());
      });
      setIndividualRegistration(filteredData);
    } else {
      fetchData();
    }
  }, [searchText]);

  const fetchData = async () => {
    // FETCH SCHOOL DATA
    const schoolData = await getSingleRecord(
      enumValues.SCHOOL_REGISTRATIONS,
      schId
    );
    setSchoolData(schoolData);
    // FETCH EVENT DATA
    const IndividualData = await findByDocumentsKey(
      enumValues.INDIVIDUAL_REGISTRATIONS,
      "school",
      schId
    );
    const modifiedEventData = IndividualData.map((student) => {
      return getSingleRecord(enumValues.EVENT_COLLECTION, student.event).then(
        (data) => {
          return {
            ...student,
            event: data,
          };
        }
      );
    });
    const modifiedEventDataPromise = await Promise.all(modifiedEventData);
    //  FILTER DATA BY SELECTED CATEGORY
    const filteredData = modifiedEventDataPromise.filter((individualData) => {
      if (selectedCategory === "Other Category") {
        return (
          individualData.event.event !== "Short film" &&
          individualData.event.event !== "Graphic designing" &&
          individualData.event.event !== "Photography"
        );
      }
      return individualData.event.event === selectedCategory;
    });
    setIndividualRegistration(filteredData);
  };
  const QRDownload = () => {
    const qrCodeURL = document
      .getElementById("STDQrCode")
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let link = document.createElement("a");
    link.download = `${selectedIndividualRegistration?.teamForm?.[0]?.stdName}-${selectedIndividualRegistration?.id}.png`;
    link.href = qrCodeURL;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const dataOpen = (index) => {
    setModelOpen(true);
    setSelectedIndividualRegistration(individualRegistration[index]);
  };

  const Language = {
    SM: "Sinhala",
    EM: "English",
  };
  const categories = [
    {
      value: "Other Category",
      name: "Other Category",
    },
    { value: "Short film", name: "Short film" },
    { value: "Graphic designing", name: "Graphic designing" },
    { value: "Photography", name: "Photography" },
  ];

  const attendanceMark = async (id) => {
    try {
      const student = individualRegistration.find(
        (student) => student._id === id
      );
      if (!student) toast.error("Failed to mark attendance");

      // UPDATE ATTENDANCE
      await updateDocument(enumValues.INDIVIDUAL_REGISTRATIONS, id, {
        attended: !student.attended,
      });
      toast.success("Attendance updated");
      fetchData();
    } catch (error) {
      toast.error("Failed to mark attendance");
    }
  };

  return (
    <DashboardLayout header="Competition Registration">
      <div className="CompetitionStudents__navigation">
        <Link to="/competition-registration">
          <i className="bx bx-chevron-left"></i> Back
        </Link>{" "}
        <h2>{schoolData.schoolName}</h2>
      </div>
      <div className="table-responsive mt-5">
        <div className="card CompetitionStudents__school__data">
          <table>
            <tr>
              <td>School name</td>
              <td>: &nbsp;{schoolData?.schoolName} </td>
            </tr>
            <tr>
              <td>Address</td>
              <td>: &nbsp;{schoolData?.schoolAddress} </td>
            </tr>
            <tr>
              <td>MU name</td>
              <td>: &nbsp;{schoolData?.mediaUnitName} </td>
            </tr>
            <tr>
              <td>MU email</td>
              <td>: &nbsp;{schoolData?.mediaUnitEmail} </td>
            </tr>
            <tr>
              <td>Name of MIC</td>
              <td>
                : &nbsp;{schoolData?.MicNamePrefix} {schoolData?.MicName}
              </td>
            </tr>
            <tr>
              <td>Contact number of MIC</td>
              <td>: &nbsp;{schoolData?.MicContactNumber}</td>
            </tr>
            <tr>
              <td>Name of President or Coordinators</td>
              <td>
                : &nbsp;{schoolData?.PresidentNamePrefix}{" "}
                {schoolData?.PresidentName}
              </td>
            </tr>
            <tr>
              <td>Whatsapp number</td>
              <td>: &nbsp;{schoolData?.presidentWhatsApp}</td>
            </tr>

            <tr>
              <td>Email address</td>
              <td>: &nbsp;{schoolData?.PresidentEmail}</td>
            </tr>
          </table>
          <div>
            <h3>School ID: {schoolData?.id}</h3>
            <span>send invitation via</span>
            <ul>
              {schoolData?.inviteMethod?.map((method) => (
                <li key={method}>{method}</li>
              ))}
            </ul>
          </div>
        </div>
        <div className="CompetitionRegistration__search mb-4">
          <h3>Individual registrations</h3>
          <div className="CompetitionRegistration__search__wrapper">
            <Formik initialValues={{ search: "" }}>
              <Form className="d-flex gap-3">
                <Select
                  options={categories}
                  onChange={(e) => {
                    setSelectedCategory(e.target.value);
                  }}
                  value={selectedCategory}
                  name="category"
                />
                <Input
                  value={searchText}
                  name="search"
                  type="search"
                  placeholder="Search"
                  onChange={(value) => setSearchText(value)}
                  wrapperClasses="CompetitionRegistration__search__input CompetitionStudents"
                />
              </Form>
            </Formik>
          </div>
        </div>
        <table>
          <thead>
            <tr>
              <th></th>
              <th>Name</th>
              <th>ID</th>
              <th>Age Cat.</th>
              <th>Event</th>
              <th>Medium</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {individualRegistration.map((student, index) => (
              <tr key={student._sysID}>
                <td>
                  {student?.event?.event ===
                    enumValues.EVENT_GRAPHIC_DESIGNING ||
                  student?.event?.event === enumValues.EVENT_SHORT_FILM ||
                  student?.event?.event === enumValues.EVENT_PHOTOGRAPHY ? (
                    <Badge
                      text={
                        student?.projectData ? "Submitted" : "Not Submitted"
                      }
                      type={student?.projectData ? "success" : "warning"}
                    />
                  ) : (
                    <Badge
                      onClick={() => attendanceMark(student?._id)}
                      text={student?.attended ? "Attended" : "Attended ?"}
                      type={student?.attended ? "success" : "warning"}
                    />
                  )}
                </td>
                <td>{student?.teamForm?.[0]?.stdName}</td>
                <td>{student?.id}</td>
                <td>{student?.ageCategory}</td>
                <td>{student?.event?.event}</td>

                <td>
                  <span className="w-100">{Language[student?.medium]}</span>
                </td>
                <td>
                  <span onClick={() => dataOpen(index)}>
                    <i className="bx bxs-archive-out"></i>
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modelOpen ? (
        <Model wrapperClassName="CompetitionStudents__model">
          <header className="w-100 d-flex align-items-center justify-content-between">
            <h4 className="text-light">Full Details</h4>
            <button onClick={() => setModelOpen(false)}>
              <i className="bx bx-x"></i> <span>Close</span>
            </button>
          </header>
          <section className="d-flex align-items-center justify-content-between mt-4 CompetitionStudents__model__Qr__section">
            <div className="table__content">
              <table>
                <tbody>
                  <tr>
                    <td>Name</td>
                    <td>
                      : {selectedIndividualRegistration?.teamForm?.[0]?.stdName}
                    </td>
                  </tr>
                  <tr>
                    <td>Birthday</td>
                    <td>
                      : {selectedIndividualRegistration?.teamForm?.[0]?.stdDOB}
                    </td>
                  </tr>
                  <tr>
                    <td>Contact Number</td>
                    <td>
                      :{" "}
                      {
                        selectedIndividualRegistration?.teamForm?.[0]
                          ?.stdContactNumber
                      }
                    </td>
                  </tr>
                  <tr>
                    <td>NIC</td>
                    <td>
                      : {selectedIndividualRegistration?.teamForm?.[0]?.nic}
                    </td>
                  </tr>
                  {selectedIndividualRegistration?.teamForm?.length > 1 ? (
                    <tr>
                      <td>Team Members</td>
                      <td>
                        <ol>
                          {selectedIndividualRegistration?.teamForm?.map(
                            (member, index) => (
                              <li key={index}>{member?.stdName} </li>
                            )
                          )}
                        </ol>
                      </td>
                    </tr>
                  ) : null}
                </tbody>
              </table>
              <div className="label__section d-flex align-items-center gap-2 mt-4 flex-wrap">
                <Badge
                  text={selectedIndividualRegistration?.id}
                  type="success"
                />
                <Badge
                  text={selectedIndividualRegistration?.event?.event}
                  type="primary"
                />
                <Badge
                  text={selectedIndividualRegistration?.ageCategory}
                  type="primary"
                />
                {selectedIndividualRegistration?.medium ? (
                  <Badge
                    text={Language[selectedIndividualRegistration?.medium]}
                    type="primary"
                  />
                ) : null}
              </div>
            </div>
            <div className="qr__code">
              <QRCode
                value={selectedIndividualRegistration?._id}
                size={200}
                style={{ height: "auto", maxWidth: "200px", width: "100%" }}
                onClick={QRDownload}
                id="STDQrCode"
              />
            </div>
          </section>
          {selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_SHORT_FILM ||
          selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_GRAPHIC_DESIGNING ||
          selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_PHOTOGRAPHY ? (
            <section className="CompetitionStudents__model__clipboard__section mt-4">
              <span className="CompetitionStudents__model__clipboard__section__title">
                The link issued for upload your design or video
              </span>
              <ClipBoardCopy
                textToCopy={`${window.location.protocol}//tcmuofficial.com${enumValues.PROJECT_PUBLISHING_URL}?individualId=${selectedIndividualRegistration?._id}`}
                wrapperClass="CompetitionStudents__model__clipboard"
              />
            </section>
          ) : null}
          {!selectedIndividualRegistration?.projectData &&
          (selectedIndividualRegistration?.event?.event ===
            enumValues.EVENT_SHORT_FILM ||
            selectedIndividualRegistration?.event?.event ===
              enumValues.EVENT_GRAPHIC_DESIGNING ||
            selectedIndividualRegistration?.event?.event ===
              enumValues.EVENT_PHOTOGRAPHY) ? (
            <section className="CompetitionStudents__model__clipboard__section mt-4">
              <span className="CompetitionStudents__model__clipboard__section__title">
                Design or video not submitted
              </span>
            </section>
          ) : null}
          {selectedIndividualRegistration?.projectData ? (
            <>
              <section className="d-flex align-items-center justify-content-between mt-4 CompetitionStudents__model__Qr__section">
                <div className="table__content">
                  <table className="">
                    <tbody>
                      <tr>
                        <td>Title</td>
                        <td>
                          :{" "}
                          {selectedIndividualRegistration?.projectData?.project}
                        </td>
                      </tr>
                      <tr>
                        <td>Description</td>
                        <td>
                          :{" "}
                          {
                            selectedIndividualRegistration?.projectData
                              ?.description
                          }
                        </td>
                      </tr>
                      <tr>
                        <td>Used softwares</td>
                        <td>
                          :{" "}
                          {
                            selectedIndividualRegistration?.projectData
                              ?.softwares
                          }
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </section>
              <section className="CompetitionStudents__model__clipboard__section mt-4">
                <span className="CompetitionStudents__model__clipboard__section__title">
                  Design or video link
                </span>
                <div className="ClipBoardCopy__copyText__area CompetitionStudents__model__clipboard">
                  <span>
                    {selectedIndividualRegistration?.projectData?.projectLink}
                  </span>
                  <i
                    className="bx bx-up-arrow-alt"
                    style={{
                      transform: "rotate(35deg)",
                    }}
                    onClick={() =>
                      window.open(
                        selectedIndividualRegistration?.projectData
                          ?.projectLink,
                        "_blank"
                      )
                    }
                  ></i>
                </div>
              </section>
            </>
          ) : null}
        </Model>
      ) : null}
    </DashboardLayout>
  );
}

export default CompetitionStudents;
