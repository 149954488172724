import React, { useEffect, useState } from "react";
import "./DashboardLayout.scss";
import { dashboardTitleImage } from "../../constants/asset";
import { Link, useLocation } from "react-router-dom";
import { getDateTimeSuffix } from "../../util/dateFormat";

/**
 * Renders the DashboardLayout component.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components to be rendered.
 * @param {string} [props.header="Dashboard"] - The header text for the dashboard layout.
 * @return {ReactElement} The rendered DashboardLayout component.
 */
function DashboardLayout({ children, header = "Dashboard" }) {
  const location = useLocation();
  const [dayString, setDayString] = useState("");
  const [timeString, setTimeString] = useState("00:00 AM");
  const [navOpen, setNavOpen] = useState(false);
  const dashboardLinks = [
    {
      path: "/",
      name: "Dashboard",
      icon: "bx bxs-dashboard",
    },
    {
      path: "/competition-registration",
      name: "Competition Registrations",
      icon: "bx bx-menu",
    },
    {
      path: "/competition-eventviselist",
      name: "Event vise list",
      icon: "bx bx-list-ul",
    },
    // {
    //   path: "/competition-attendance",
    //   name: "Competition Attendance",
    //   icon: "bx bx-qr-scan",
    // },
    {
      path: "/day-registration",
      name: "Day Registrations",
      icon: "bx bx-menu",
    },
    {
      path: "/day-feedbacks",
      name: "Prabhashwara Feedbacks",
      icon: "bx bx-conversation",
    },
    {
      path: "/news",
      name: "News",
      icon: "bx bx-news",
    },
    {
      path: "/prabhashwara-merchandise",
      name: "Prabhashwara Merchandise",
      icon: "bx bx-store-alt",
    },
  ];
  useEffect(() => {
    const today = new Date();
    const month = today
      .toLocaleDateString("en-US", {
        day: "numeric",
        month: "long",
        year: "numeric",
        weekday: "long",
      })
      .split(",")[1]
      .split(" ")[1];
    setDayString(
      `${today.getDate()}${getDateTimeSuffix(
        today.getDate()
      )} of ${month}, ${today.getFullYear()}`
    );
    let hours = today.getHours() % 12;
    hours = hours ? hours : 12;
    setTimeString(
      `${hours}:${today.getMinutes().toString().padStart(2, "0")} ${
        today.getHours() >= 12 ? "PM" : "AM"
      }`
    );

    const intervalId = setInterval(() => {
      const today = new Date();
      let hours = today.getHours() % 12;
      hours = hours ? hours : 12;
      setTimeString(
        `${hours}:${today.getMinutes().toString().padStart(2, "0")} ${
          today.getHours() >= 12 ? "PM" : "AM"
        }`
      );
    }, 30000);

    // Clear the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, []);
  return (
    <div className="DashboardLayout container-fluid p-0">
      <div className="row m-0 p-0">
        <div
          className={`col-lg-2 col-md-12 col-sm-12 p-0 DashboardLayout__sidebar ${
            navOpen ? "active" : ""
          }`}
          onClick={() => setNavOpen(false)}
        >
          <div className="DashboardLayout__sidebar__wrapper p-4">
            <div className="Dashboard__sidebar__links w-100">
              <a href="/">
              <img
                src={dashboardTitleImage}
                alt="dashboardTitle"
                className="Dashboard__sidebar__title d-block mx-auto mb-4"
              />
              </a>
              <ul>
                {dashboardLinks.map((link) => (
                  <li
                    key={link.name}
                    className={location.pathname === link.path ? "active" : ""}
                  >
                    <i className={`bx ${link.icon}`}></i> &nbsp;
                    <Link to={link.path}>{link.name}</Link>
                  </li>
                ))}
              </ul>
            </div>
            <div className="Dashboard__sidebar__profile w-100">
              <div className="Dashboard__sidebar__profile__admin">
                <i className="bx bxs-label bx-rotate-90"></i> &nbsp;{" "}
                <h5>
                  ADMIN PROFILE <br />
                  <small>Administrator</small>
                </h5>
              </div>
              <hr />
              <div className="Dashboard__sidebar__profile__logout">
                <Link to="/logout">
                  <i className="bx bx-power-off"></i> &nbsp; Logout
                </Link>
              </div>
            </div>
            <p className="version_">V1.2.1</p>
          </div>
        </div>
        <div className="col-lg-10 col-md-12 col-sm-12 pt-4 DashboardLayout__content">
          <header className="DashboardLayout__header w-100 p-2">
            <i
              className="bx bx-menu-alt-left DashboardLayout__header__menu"
              onClick={() => setNavOpen(!navOpen)}
            ></i>
            <h1>{header}</h1>
            <div className="DashboardLayout__header__timer">
              <span>{timeString}</span> | <span>{dayString}</span>
            </div>
          </header>
          <div className="content__wrapper w-100">{children}</div>
        </div>
      </div>
    </div>
  );
}

export default DashboardLayout;
