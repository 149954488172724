import { toast } from "react-toastify";
import { auth } from "../config/firebase";
import { signInWithEmailAndPassword } from "firebase/auth";

/**
 * Function to log in a user using their email and password.
 * It calls the Firebase signInWithEmailAndPassword method and handles any errors that occur.
 *
 * @param {string} email - The email of the user.
 * @param {string} password - The password of the user.
 * @return {Promise<Object>} - A promise that resolves to the user object of the logged in user.
 * @throws {Error} - If there is an error while logging in the user, it throws an error.
 */
const login = async (email, password) => {
  try {
    // Call the Firebase signInWithEmailAndPassword method to log in the user
    const authData = await signInWithEmailAndPassword(auth, email, password);
    // Show a success toast message
    toast.success("Login Successful");
    // Return the user object of the logged in user
    return authData.user;
  } catch (error) {
    // Show an error toast message
    toast.error("Invalid Login Credentials");
  }
};

/**
 * Function to sign out the user from the application.
 * It calls the Firebase signOut method and handles any errors that occur.
 *
 * @return {Promise<void>} - A promise that resolves when the user is successfully signed out.
 * @throws {Error} - If there is an error while signing out the user, it throws an error.
 */
const signOut = async () => {
  try {
    // Call the Firebase signOut method to sign out the user
    await auth.signOut();
  } catch (error) {
    // If there is an error, display an error toast with the error message
    toast.error(error.message);
  }
};

export { login, signOut };
