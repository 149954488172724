import React, { useEffect, useState } from "react";
import "./DayRegistration.scss";
import DashboardLayout from "../../../../layouts/Dashboard/DashboardLayout";
import { toast } from "react-toastify";
import { getAllRecords } from "../../../../hooks/useFetch";
import enumValues from "../../../../constants/enum";
import Model from "../../../../components/Model";
import Badge from "../../../../components/Badge";

function DayRegistration() {
  const [data, setData] = useState([]);
  const [totalRegistrations, setTotalRegistrations] = useState(0);
  const [totalParticipants, setTotalParticipants] = useState(0);
  const [modelOpen, setModelOpen] = useState(false);
  const [modelData, setModelData] = useState({});
  useEffect(() => {
    fetchData();
  }, []);
  /**
   * Fetches all records from the DAY_REGISTRATIONS collection and updates the state variables accordingly.
   * If there is an error in fetching the data, it shows an error toast.
   */
  const fetchData = async () => {
    try {
      // Fetch all records from the DAY_REGISTRATIONS collection in ascending order
      const data = await getAllRecords(enumValues.DAY_REGISTRATIONS, "asc");

      // Calculate the total number of participants by summing up the 'teamFormCount' property of each record
      const totalParticipants = data.reduce(
        (total, item) => (total += item.teamFormCount),
        0
      );

      // Update the state variable totalParticipants with the calculated total number of participants
      setTotalParticipants(totalParticipants);

      // Update the state variable totalRegistrations with the number of records fetched
      setTotalRegistrations(data.length);

      // Update the state variable data with the fetched records
      setData(data);
    } catch (error) {
      // Show an error toast if there is an error in fetching the data
      toast.error("Failed to fetch data");
    }
  };
  /**
   * Opens the model and sets the model data to the selected record from the data array.
   *
   * @param {number} index - The index of the selected record in the data array.
   */
  const dataOpen = (index) => {
    // Set the modelOpen state variable to true to open the model
    setModelOpen(true);

    // Set the modelData state variable to the selected record from the data array
    setModelData(data[index]);
  };

  return (
    <DashboardLayout header="Day Registrations">
            <div className="row m-0 p-0 Dashboard__cards">
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card">
                  <h5>Total Registrations:</h5>
                  <h1>{totalRegistrations.toString().padStart(3, "0")}</h1>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 col-sm-12 mb-4">
                <div className="card">
                  <h5>Total Participants:</h5>
                  <h1>{totalParticipants.toString().padStart(3, "0")}</h1>
                </div>
              </div>
            </div>
      <div className="table-responsive mt-5">
        <table>
          <thead>
            <tr>
              <th>School Name</th>
              <th>MU Name</th>
              <th>Count</th>
              <th>Participation</th>
              <th></th>
            </tr>
          </thead>
          <tbody>
            {data.map((item,index) => (
              <tr key={item._id}>
               <td>{item?.school}</td>
               <td>{item?.mediaUnitName}</td>
               <td>{item?.teamFormCount}</td>
               <td>
                <ol>
                  {item?.participantNames?.map((participant) => (
                    <li key={participant}>{participant}</li>
                  ))}
                </ol>
               </td>
               <td>
               <span onClick={() => dataOpen(index)}>
                    <i className="bx bxs-archive-out"></i>
                  </span>
               </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {modelOpen ? (
        <Model wrapperClassName="CompetitionStudents__model">
          <header className="w-100 d-flex align-items-center justify-content-between">
            <h4 className="text-light">Full Details</h4>
            <button onClick={() => setModelOpen(false)}>
              <i className="bx bx-x"></i> <span>Close</span>
            </button>
          </header>
          <h5 className="text-center text-light"> {modelData?.school} - {modelData?.mediaUnitName}</h5>
          <section className="d-flex align-items-center justify-content-center mt-4 CompetitionStudents__model__Qr__section">
            <div className="table__content">
              <table>
                <tbody>
                <tr>
                  <td>MU Email</td>
                  <td>{modelData?.mediaUnitEmail}</td>
                </tr>
                <tr>
                  <td>MU Contact</td>
                  <td>{modelData?.micContactNumber}</td>
                </tr>
                  <tr>
                  <td>MU President</td>
                  <td>{modelData?.presidentNamePrefix} {modelData?.presidentName}</td>
                  </tr>
                  <tr>
                  <td>MU President Mobile</td>
                  <td>{modelData?.presidentWhatsApp}</td>
                  </tr>
                <tr>
                  <td>MIC</td>
                  <td>{modelData?.micNamePrefix} {modelData?.micName}</td>
                  </tr>
                  <tr>
                  <td>School address</td>
                  <td>{modelData?.schoolAddress}</td>
                  </tr>
                </tbody>
              </table>
             
            </div>
        
          </section>

          <section className=" mt-4 CompetitionStudents__model__Qr__section">
            <h5 className="text-light"> Participation Details</h5>
            <ol>
              {modelData?.participantNames?.map((participant) => (
                <li key={participant} className="text-light">{participant}</li>
              ))}
            </ol>
          </section>
          <h5 className="text-light mt-4"> Invite Methods</h5>
          <div className="label__section d-flex align-items-center gap-2 flex-wrap">
               {
                modelData?.inviteMethod?.map((method) => (
                  <Badge
                    text={method}
                    type="success"
                    key={method}
                  />
                ))
               }
               
               
              </div>
        
        </Model>
      ) : null}



    </DashboardLayout>
  );
}

export default DayRegistration;
