import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import Login from "./pages/auth/Login";
import Dashboard from "./pages/auth/Dashboard/Dashboard";

import CompetitionRegistration from "./pages/auth/Dashboard/CompetitionRegistration";
import CompetitionStudents from "./pages/auth/Dashboard/CompetitionStudents";
import DayRegistration from "./pages/auth/Dashboard/DayRegistration";
import News from "./pages/auth/Dashboard/News";
import Logout from "./pages/auth/Logout";

import Auth from "./guards/Auth";
import AttendanceMark from "./pages/auth/Dashboard/AttendanceMark";
import EventViseList from "./pages/auth/Dashboard/EventViseList";
import Merchandise from "./pages/auth/Dashboard/Merchandise";
import DayFeedBacks from "./pages/auth/Dashboard/DayFeedBacks";

function Router() {
  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      {/* PRIVATE PAGES */}
      <Route
        path="/"
        element={
          <Auth>
            <Dashboard />
          </Auth>
        }
      />
      <Route
        path="/competition-attendance"
        element={
          <Auth>
            <AttendanceMark />
          </Auth>
        }
      />
      <Route
        path="/competition-registration"
        element={
          <Auth>
            <CompetitionRegistration />
          </Auth>
        }
      />
      <Route
        path="/competition-eventviselist"
        element={
          <Auth>
            <EventViseList />
          </Auth>
        }
      />
      <Route
        path="/day-registration"
        element={
          <Auth>
            <DayRegistration />
          </Auth>
        }
      />
      <Route
        path="/day/registration/:schId"
        element={
          <Auth>
            <CompetitionStudents />
          </Auth>
        }
      />
      <Route
        path="/news"
        element={
          <Auth>
            <News />
          </Auth>
        }
      />
      <Route
        path="/prabhashwara-merchandise"
        element={
          <Auth>
            <Merchandise />
          </Auth>
        }
      />
      
      <Route
        path="/day-feedbacks"
        element={
          <Auth>
            <DayFeedBacks />
          </Auth>
        }
      />

      <Route
        path="/logout"
        element={
          <Auth>
            <Logout />
          </Auth>
        }
      />
      {/* PRIVATE PAGES */}
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
}

export default Router;
